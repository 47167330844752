@import "vars";

@each $name, $size in $margin-sizes {
  .inner-#{$name} {
    padding: #{$size}px;
  }

  .outer-#{$name} {
    margin: #{$size}px;
  }

  @each $direction in $directions {
    .inner-#{$direction}-#{$name} {
      padding-#{$direction}: #{$size}px;
    }

    .outer-#{$direction}-#{$name} {
      margin-#{$direction}: #{$size}px;
    }
  }
}

/**
* COLORS STYLES
**/
@each $color-name, $color in $colors {
  .color-#{$color-name} {
    color: $color !important;
  }

  .bg-#{$color-name} {
    background-color: $color !important;
  }

  .border-color-#{$color-name} {
    border-color: $color !important;
  }

  .b-#{$color-name} {
    border: 1px solid $color !important;
  }

  .b-b-#{$color-name} {
    border-bottom: 2px solid $color !important;
  }

  .b-l-#{$color-name} {
    border-left: 3px solid $color !important;
  }

  md-checkbox.#{$color-name}.md-checked .md-icon {
    background-color: opacify($color, 0.87);
  }

  .md-button.md-fab.#{$color-name} {
    background-color: $color;

    &:hover {
      background-color: opacify($color, 0.9);
    }
  }

}

@each $percent in $percents {
  .w-#{$percent}-p {
    width: #{$percent + '%'};
  }
}
